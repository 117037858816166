<template>
  <v-card>
    <v-card-title>
      Data User
      <v-spacer></v-spacer>
      <v-btn
        v-if="data.isBanned == 'false'"
        small
        outlined
        @click="reportAction('true')"
        color="red"
      >
        <v-icon left> mdi-account-off </v-icon>
        Banned User
      </v-btn>
      <v-btn
        v-if="data.isBanned == 'true'"
        small
        outlined
        @click="reportAction('false')"
        color="green"
      >
        <v-icon left> mdi-account-check </v-icon>
        Unbanned User
      </v-btn>

      <v-btn
        v-if="data.isVerified == false"
        small
        outlined
        @click="verifyAction('true')"
        color="green"
      >
        <v-icon left> mdi-check-circle </v-icon>
        Verified User
      </v-btn>
      <v-btn
        v-if="data.isVerified == true"
        small
        outlined
        @click="verifyAction('false')"
        color="orange"
      >
        <v-icon left> mdi-close-circle </v-icon>
        Unverified User
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-avatar class="profile" color="grey" size="164">
        <img
          v-if="data.profilePictureUrl"
          :src="IMAGE_URL + data.profilePictureUrl"
        />
      </v-avatar>
      <v-row>
        <v-col cols="6">
          <v-list dense>
            <v-list-item-group
              v-for="(item, i) in data"
              :key="i"
              color="primary"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ format_value(i) }}</v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item || 'Kosong'"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Game Name</th>
                  <th class="text-left">Game Id</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data.game_list" :key="item.name">
                  <td>
                    <v-list-item-avatar>
                      <img :src="IMAGE_URL + item.game.imageUrl" />
                    </v-list-item-avatar>
                    {{ item.game.name }}
                  </td>
                  <td>{{ item.gameUsername }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-alert
            border="right"
            colored-border
            type="error"
            elevation="2"
            v-if="data.game_list.length == 0"
          >
            Data game tidak ditemukan
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "UserDetail",

  components: {},

  data() {
    return {
      IMAGE_URL: process.env.VUE_APP_IMAGE_URL,
      data: {
        user: {
          profilePictureUrl: null,
        },
      },
    };
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },

    format_value(value) {
      if (value) {
        return value
          .replace(/(_|-)/g, " ")
          .trim()
          .replace(/\w\S*/g, function (str) {
            return str.charAt(0).toUpperCase() + str.substr(1);
          })
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
      }
    },

    reportAction(action) {
      let message = action == "true" ? "banned" : "unbanned";
      let doConfirm = window.confirm(`Sure to ${message} this?`);

      if (doConfirm) {
        let config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        };

        axios
          .create({ withCredentials: false })
          .get(
            `${process.env.VUE_APP_API_URL}/v1/admin/user/${this.$route.params.id}/banned_action/${action}`,
            config
          )
          .then((response) => {
            this.fetch();
            this.$toast.success(response.data.message);
          })
          .catch((error) => {
            this.$toast.warning(error.response.data.message || "Unauthorized");
            this.loading = false;
          });
      }
    },

    verifyAction(action) {
      let message = action == "true" ? "verified" : "unverified";
      let doConfirm = window.confirm(`Sure to ${message} this?`);

      if (doConfirm) {
        let config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        };

        axios
          .create({ withCredentials: false })
          .get(
            `${process.env.VUE_APP_API_URL}/v1/admin/user/${this.$route.params.id}/verification_action/${action}`,
            config
          )
          .then((response) => {
            this.fetch();
            this.$toast.success(response.data.message);
          })
          .catch((error) => {
            this.$toast.warning(error.response.data.message || "Unauthorized");
            this.loading = false;
          });
      }
    },

    fetch() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      axios
        .create({ withCredentials: false })
        .get(
          `${process.env.VUE_APP_API_URL}/v1/admin/user/${this.$route.params.id}`,
          {
            headers: { Authorization: AuthStr },
          }
        )
        .then((response) => {
          this.loading = false;

          this.data = response.data.result;

          // console.log(response.data);
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
          console.log(error);
        });
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>
